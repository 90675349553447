<template v-if="preferenceUpdateData">
  <ul class="clebex-item-section date-time-format">
    <li
      class="clebex-item-section-item full-right-underline"
      :class="preferenceUpdateData.is_automatic ? 'disabled' : ''"
    >
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">{{ exampleTime }}</label>
        </div>
      </div>
    </li>
    <li
      class="clebex-item-section-item full-right-underline"
      :class="preferenceUpdateData.is_automatic ? 'disabled' : ''"
    >
      <div class="clebex-item-content-wrapper time-format-label">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label"
            >{{
              displayLabelName("preferences", "set-preferences", "hour")
            }}
            (AM/PM)</label
          >
        </div>
        <div class="clebex-section-input">
          <label class="clebex-section-input-label pl-2"
            >{{
              displayLabelName("preferences", "set-preferences", "hour")
            }}
            (24h)</label
          >
        </div>
      </div>
      <div class="clebex-item-content-wrapper">
        <section class="clebex-pill-menu-tabs">
          <ul class="clebex-pill-tabs">
            <li class="clebex-pill-tabs-content visible">
              <div class="select-time-picker visible">
                <ul
                  class="list-of-time-slots list-of-hours date-time-preference"
                >
                  <section class="date-time-section pr-2">
                    <li class="select-time-button-wrapper double">
                      <button
                        data-skip
                        class="select-time-btn"
                        :class="{ selected: hour === 'h' }"
                        type="button"
                        @click="
                          hour = 'h';
                          setData();
                        "
                      >
                        h
                      </button>
                    </li>
                    <li class="select-time-button-wrapper double">
                      <button
                        data-skip
                        class="select-time-btn"
                        :class="{ selected: hour === 'hh' }"
                        type="button"
                        @click="
                          hour = 'hh';
                          setData();
                        "
                      >
                        hh
                      </button>
                    </li>
                  </section>
                  <section class="date-time-section pl-2">
                    <li class="select-time-button-wrapper double">
                      <button
                        data-skip
                        class="select-time-btn"
                        :class="{ selected: hour === 'H' }"
                        type="button"
                        @click="
                          hour = 'H';
                          setData();
                        "
                      >
                        H
                      </button>
                    </li>
                    <li class="select-time-button-wrapper double">
                      <button
                        data-skip
                        class="select-time-btn"
                        :class="{ selected: hour === 'HH' }"
                        type="button"
                        @click="
                          hour = 'HH';
                          setData();
                        "
                      >
                        HH
                      </button>
                    </li>
                  </section>
                </ul>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </li>
    <li
      class="clebex-item-section-item full-right-underline no-separator"
      :class="preferenceUpdateData.is_automatic ? 'disabled' : ''"
    >
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">{{
            displayLabelName("preferences", "set-preferences", "minutes")
          }}</label>
        </div>
      </div>
      <div class="clebex-item-content-wrapper">
        <section class="clebex-pill-menu-tabs">
          <ul class="clebex-pill-tabs">
            <li class="clebex-pill-tabs-content visible">
              <div class="select-time-picker visible">
                <ul class="list-of-time-slots list-of-hours">
                  <li class="select-time-button-wrapper double">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: minute === 'm' }"
                      type="button"
                      @click="
                        minute = 'm';
                        setData();
                      "
                    >
                      m
                    </button>
                  </li>
                  <li class="select-time-button-wrapper double">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: minute === 'mm' }"
                      type="button"
                      @click="
                        minute = 'mm';
                        setData();
                      "
                    >
                      mm
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";

export default {
  name: "TimeFormat",
  data() {
    return {
      initialValue: null,
      hour: null,
      minute: null,
      exampleTime: null,
      newValue: null
    };
  },
  mounted() {
    this.setInitialData();
  },
  computed: {
    ...mapState("preferences", ["preferences", "preferenceUpdateData"])
  },
  props: {
    preferenceId: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions("preferences", ["setPreferenceUpdateData", "setPreferences"]),
    setInitialData() {
      this.initialValue = this.preferenceUpdateData.values[0].value;
      const initialValueArray = this.initialValue.split(":");
      this.hour = initialValueArray[0];
      this.minute = initialValueArray[1];
      this.setNewValue();
    },
    setData() {
      if (this.preferenceUpdateData.is_automatic) {
        return;
      }
      this.setNewValue();
      const newPreferenceUpdateData = {
        is_automatic: this.preferenceUpdateData.is_automatic,
        values: [
          {
            value: this.newValue
          }
        ]
      };
      if (this.preferenceUpdateData.values[0].id) {
        newPreferenceUpdateData.values[0].id = this.preferenceUpdateData.values[0].id;
      }
      this.setPreferenceUpdateData(newPreferenceUpdateData);
    },
    setNewValue() {
      this.newValue = `${this.hour}:${this.minute}`;
      this.exampleTime = `${dayjs().format(this.newValue)} ${
        this.hour.charAt(0) === "h" ? dayjs().format("A") : ""
      }`;
    }
  }
};
</script>
